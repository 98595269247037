<template>
  <!--底部bar左侧自定义页面window方式弹出-->
  <div class="popup" ref="myPopup">
    <div class="popupHeader">
      <div class="popupHeaderTitle">
        <svg class="iconImg" aria-hidden="true">
          <use :href="'#' + JSON.parse(popupIcon)['code']"></use>
        </svg>
        <div class="title">{{ popupTitle }}</div>
      </div>
      <div class="popupHeaderBtn">
        <!--          弹回-->
        <i class="el-icon-bottom-left" @click="rebound" title="弹入"></i>
        <!--        <i class="el-icon-position" style="margin-left: 10px"></i>-->
      </div>
    </div>
    <div class="popupBody">
      <cc-renderer
        :componentData="componentData"
        :canvasStyleData="canvasStyleData"
        v-loading="loading"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      // 渲染器所需数据
      componentData: [],
      canvasStyleData: {},
      loading: false,
      // 图标
      popupIcon: "",
      //  title
      popupTitle: "1111",
      // 当前弹出窗的数据
      currentPoupWindowData: {},
      // 区分弹回和关闭
      flag: true,
    };
  },
  mounted() {
    // 隐藏全局加载框
    document.getElementById("lightning-loading").style.display = "none";
    // 当前弹出窗的数据
    this.currentPoupWindowData = JSON.parse(this.$route.query.clickTabItem);
    this.popupIcon = this.currentPoupWindowData.icon;
    this.popupTitle = this.currentPoupWindowData.label;
    // 加载自定义页面数据
    this.init(this.currentPoupWindowData.attribute);
    let that = this;
    window.addEventListener("unload", function () {
      if (that.flag) {
        window.opener.postMessage(
          {
            currentPoupWindowData: that.currentPoupWindowData,
            key: "close",
          },
          "*"
        );
      }
    });
  },
  methods: {
    // 获取自定义页面数据
    getCustomPageDetail(pageApi) {
      let parameter = {
        pageApi: pageApi,
      };
      return this.$devHttp.postFormat(
        `${window.Glod["ccex-dev"]}/custom/pc/1.0/post/detailCustomPage`,
        parameter
      );
    },
    async init(pageApi) {
      // 请求弹出框的页面数据
      this.loading = true;
      let res = await this.getCustomPageDetail(pageApi);
      if (res.returnCode == 200) {
        this.componentData = JSON.parse(res.data.pageContent);
        this.canvasStyleData = JSON.parse(res.data.canvasStyleData);
      } else {
        this.$message.warning(res.returnInfo);
      }
      this.loading = false;
    },
    // 弹回
    rebound() {
      // 弹回window.close()之后不走 unload关闭方法
      this.flag = false;
      window.opener.postMessage(
        {
          currentPoupWindowData: this.currentPoupWindowData,
          key: "bounce",
        },
        "*"
      );
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  width: 100%;
  height: 100%;
  background: #ffffff;
  /*box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.2);*/
  display: flex;
  flex-direction: column;
  .popupHeader {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #b583d9;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    .popupHeaderTitle {
      height: 100%;
      display: flex;
      align-items: center;
      .iconImg {
        width: 18px;
        height: 18px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        margin-left: 10px;
      }
    }
    .popupHeaderBtn {
      display: flex;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .popupBody {
    width: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>